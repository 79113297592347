<template>
  <div class="students-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Students</span>
    </nav>

    <h2 class="title">Students</h2>

    <div class="controls">
      <input v-model="filters.firstName" placeholder="Filter by first name" />
      <input v-model="filters.lastName" placeholder="Filter by last name" />
      <input v-model="filters.school" placeholder="Filter by school" />
      <input v-model="filters.group" placeholder="Filter by group" />
      <select v-model="itemsPerPage">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="100">100</option>
        <option value="all">All</option>
      </select>
      <button @click="exportToCSV">Export CSV</button>
      <button @click="exportToExcel">Export Excel</button>
      <button @click="addStudent" class="create-button">Add Student</button>
    </div>

    <table class="students-table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>School</th>
          <th>Group</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in filteredStudents" :key="student.id">
          <td>{{ student.firstName }}</td>
          <td>{{ student.lastName }}</td>
          <td>{{ student.school ? student.school.name : "" }}</td>
          <td>{{ student.group ? student.group.name : "" }}</td>
          <td>
            <button @click="viewStudent(student.id)" class="view-button">
              View
            </button>
            <button @click="editStudent(student.id)" class="edit-button">
              Edit
            </button>
            <button @click="deleteStudent(student.id)" class="delete-button">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config"; // Import the API URL from the config file

// Define the types for the data
interface Student {
  id: number;
  firstName: string;
  lastName: string;
  school?: { id: number; name: string };
  group?: { id: number; name: string };
}

export default defineComponent({
  name: "StudentsView",
  setup() {
    const students = ref<Student[]>([]);
    const itemsPerPage = ref<string | number>(10);
    const filters = ref({
      firstName: "",
      lastName: "",
      school: "",
      group: "",
    });
    const router = useRouter();

    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URL}/students`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          students.value = await response.json();
        } else {
          console.error("Failed to fetch students:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    onMounted(() => {
      fetchStudents();
    });

    const filteredStudents = computed(() => {
      let filtered = students.value;
      if (filters.value.firstName) {
        filtered = filtered.filter((student) =>
          student.firstName
            .toLowerCase()
            .includes(filters.value.firstName.toLowerCase())
        );
      }
      if (filters.value.lastName) {
        filtered = filtered.filter((student) =>
          student.lastName
            .toLowerCase()
            .includes(filters.value.lastName.toLowerCase())
        );
      }
      if (filters.value.school) {
        filtered = filtered.filter((student) =>
          student.school?.name
            .toLowerCase()
            .includes(filters.value.school.toLowerCase())
        );
      }
      if (filters.value.group) {
        filtered = filtered.filter((student) =>
          student.group?.name
            .toLowerCase()
            .includes(filters.value.group.toLowerCase())
        );
      }
      if (itemsPerPage.value !== "all") {
        const limit = Number(itemsPerPage.value);
        filtered = filtered.slice(0, limit);
      }
      return filtered;
    });

    const exportToCSV = () => {
      // Implement CSV export functionality
      console.log("Exporting to CSV");
    };

    const exportToExcel = () => {
      // Implement Excel export functionality
      console.log("Exporting to Excel");
    };

    const addStudent = () => {
      router.push({ name: "AddStudent" });
    };

    const editStudent = (id: number) => {
      router.push({ name: "EditStudent", params: { id } });
    };

    const viewStudent = (id: number) => {
      router.push({ name: "ViewStudent", params: { id } });
    };

    const deleteStudent = async (id: number) => {
      if (confirm("Are you sure you want to delete this student?")) {
        try {
          const response = await fetch(`${API_URL}/students/${id}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.ok) {
            fetchStudents(); // Refresh the list after deletion
          } else {
            console.error("Failed to delete student:", response.statusText);
          }
        } catch (error) {
          console.error("Error deleting student:", error);
        }
      }
    };

    return {
      students,
      itemsPerPage,
      filters,
      filteredStudents,
      exportToCSV,
      exportToExcel,
      addStudent,
      editStudent,
      viewStudent,
      deleteStudent,
    };
  },
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.students-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.controls input,
.controls select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  flex: 1 1 200px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.create-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
}

.create-button:hover {
  background-color: #218838;
}

.view-button {
  background-color: #17a2b8;
  color: #fff;
}

.view-button:hover {
  background-color: #138496;
}

.edit-button {
  background-color: #ffc107;
  color: #fff;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #dc3545;
  color: #fff;
}

.delete-button:hover {
  background-color: #c82333;
}
</style>
