import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'app-container': true, 'with-sidebar': _ctx.isAuthenticated })
  }, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_NavigationMenu, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ])
  ], 2))
}