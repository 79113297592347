<template>
  <div class="contact-form-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/contacts">Contacts</router-link> /
      <span>{{ isEditMode ? "Edit Contact" : "Create Contact" }}</span>
    </nav>

    <h2 class="title">{{ isEditMode ? "Edit Contact" : "Create Contact" }}</h2>

    <form @submit.prevent="saveContact">
      <!-- Name Field -->
      <div class="form-group">
        <label for="name">Name:</label>
        <input v-model="contact.name" id="name" required />
      </div>

      <!-- Phone Field -->
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input v-model="contact.phone" id="phone" />
      </div>

      <!-- Email Field -->
      <div class="form-group">
        <label for="email">Email:</label>
        <input v-model="contact.email" id="email" />
      </div>

      <button type="submit" class="save-button">
        {{ isEditMode ? "Save Changes" : "Create Contact" }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { API_URL } from "../config";

const contact = ref({ name: "", phone: "", email: "" });
const isEditMode = ref(false);
const router = useRouter();
const route = useRoute();

const fetchContact = async (id) => {
  try {
    const response = await fetch(`${API_URL}/contacts/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      contact.value = await response.json();
    } else {
      console.error("Failed to fetch contact:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching contact:", error);
  }
};

const saveContact = async () => {
  const method = isEditMode.value ? "PUT" : "POST";
  const url = isEditMode.value
    ? `${API_URL}/contacts/${contact.value.id}`
    : `${API_URL}/contacts`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(contact.value),
    });

    if (response.ok) {
      router.push("/contacts");
    } else {
      console.error("Failed to save contact:", response.statusText);
    }
  } catch (error) {
    console.error("Error saving contact:", error);
  }
};

onMounted(() => {
  const id = Number(route.params.id);
  if (id) {
    isEditMode.value = true;
    fetchContact(id);
  } else {
    isEditMode.value = false;
  }
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.contact-form-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.save-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  width: 100%;
}
</style>
