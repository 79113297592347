<template>
  <div class="dashboard-container">
    <h2>Welcome to the NavaRoute</h2>

    <!-- Driver Alerts Section -->
    <div class="card">
      <h3>Driver Alerts</h3>
      <ul>
        <li v-for="alert in driverAlerts" :key="alert.id">
          {{ alert.message }} - <strong>{{ alert.date }}</strong>
        </li>
      </ul>
    </div>

    <!-- Time Off / PTO Section -->
    <div class="card">
      <h3>Time Off / PTO</h3>
      <ul>
        <li v-for="pto in timeOffPTO" :key="pto.id">
          {{ pto.name }} requested {{ pto.days }} days off starting from
          <strong>{{ pto.startDate }}</strong>
        </li>
      </ul>
    </div>

    <!-- School Closings Section -->
    <div class="card">
      <h3>School Closings</h3>
      <ul>
        <li v-for="closing in schoolClosings" :key="closing.id">
          {{ closing.schoolName }} - Closed on
          <strong>{{ closing.date }}</strong> due to {{ closing.reason }}
        </li>
      </ul>
    </div>

    <!-- Company Notifications Section -->
    <div class="card">
      <h3>Company Notifications</h3>
      <ul>
        <li v-for="notification in companyNotifications" :key="notification.id">
          {{ notification.message }} - <strong>{{ notification.date }}</strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  data() {
    return {
      driverAlerts: [
        {
          id: 1,
          message: "Driver A is late by 15 minutes",
          date: "2024-08-10",
        },
        { id: 2, message: "Driver B reported a flat tire", date: "2024-08-10" },
        { id: 3, message: "Driver C missed a pickup", date: "2024-08-09" },
      ],
      timeOffPTO: [
        { id: 1, name: "John Doe", days: 3, startDate: "2024-08-15" },
        { id: 2, name: "Jane Smith", days: 5, startDate: "2024-08-18" },
      ],
      schoolClosings: [
        {
          id: 1,
          schoolName: "Lincoln High School",
          date: "2024-08-12",
          reason: "Weather conditions",
        },
        {
          id: 2,
          schoolName: "Washington Elementary",
          date: "2024-08-13",
          reason: "Power outage",
        },
      ],
      companyNotifications: [
        { id: 1, message: "Quarterly meeting scheduled", date: "2024-08-11" },
        { id: 2, message: "New company policies updated", date: "2024-08-10" },
      ],
    };
  },
});
</script>

<style scoped>
.dashboard-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

h2 {
  color: #2c3e50;
  text-align: center;
}

p {
  color: #7f8c8d;
  text-align: center;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #2c3e50;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  color: #34495e;
}

li strong {
  color: #2980b9;
}
</style>
