<template>
  <div class="contacts-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Contacts</span>
    </nav>

    <h2 class="title">Contacts</h2>

    <button @click="createNewContact" class="create-button">
      Create New Contact
    </button>

    <table class="contacts-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" :key="contact.id">
          <td>{{ contact.id }}</td>
          <td>{{ contact.name }}</td>
          <td>{{ contact.phone || "N/A" }}</td>
          <td>{{ contact.email || "N/A" }}</td>
          <td>
            <button @click="editContact(contact.id)" class="edit-button">
              Edit
            </button>
            <button @click="deleteContact(contact.id)" class="delete-button">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";

const contacts = ref([]);
const router = useRouter();

const fetchContacts = async () => {
  try {
    const response = await fetch(`${API_URL}/contacts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      contacts.value = await response.json();
    } else {
      console.error("Failed to fetch contacts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching contacts:", error);
  }
};

const createNewContact = () => {
  router.push("/contacts/new");
};

const editContact = (id) => {
  router.push(`/contacts/${id}/edit`);
};

const deleteContact = async (id) => {
  if (confirm("Are you sure you want to delete this contact?")) {
    try {
      const response = await fetch(`${API_URL}/contacts/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        fetchContacts(); // Refresh the list after deletion
      } else {
        console.error("Failed to delete contact:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  }
};

onMounted(fetchContacts);
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.contacts-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.create-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.contacts-table {
  width: 100%;
  border-collapse: collapse;
}

.contacts-table th,
.contacts-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
}

.delete-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}
</style>
