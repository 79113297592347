import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import ImportData from "../views/ImportData.vue"; // Import the new component
import OrganizationsView from "../views/OrganizationsView.vue"; // Import OrganizationsView
import EditOrganization from "../views/EditOrganization.vue";
import DistrictsView from "../views/DistrictsView.vue";
import DistrictForm from "../views/DistrictForm.vue";
import ContactsView from "../views/ContactsView.vue"; // Import ContactsView
import ContactForm from "../views/ContactForm.vue"; // Import ContactForm
import EmployeesView from "../views/EmployeesView.vue"; // Import EmployeesView
import ViewOrganizationView from "@/views/ViewOrganizationView.vue";
import StudentView from "../views/StudentView.vue"; // Import StudentView
import AddStudentView from "../views/AddStudentView.vue"; // Import AddStudentView
import EditStudentView from "../views/EditStudentView.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/import",
    name: "ImportData",
    component: ImportData,
    meta: { requiresAuth: true },
  },
  {
    path: "/organizations", // Add route for OrganizationsView
    name: "organizations",
    component: OrganizationsView,
  },
  {
    path: "/edit/:id",
    name: "EditOrganization",
    component: EditOrganization,
  },
  {
    path: "/organizations/:id",
    name: "ViewOrganization",
    component: ViewOrganizationView,
  },
  {
    path: "/districts",
    name: "DistrictsView",
    component: DistrictsView,
  },
  {
    path: "/districts/new",
    name: "CreateDistrict",
    component: DistrictForm,
  },
  {
    path: "/districts/:id/edit",
    name: "EditDistrict",
    component: DistrictForm,
    props: true,
  },
  {
    path: "/contacts",
    name: "ContactsView",
    component: ContactsView,
  },
  {
    path: "/contacts/new",
    name: "CreateContact",
    component: ContactForm,
  },
  {
    path: "/contacts/:id/edit",
    name: "EditContact",
    component: ContactForm,
    props: true,
  },
  {
    path: "/employees",
    name: "EmployeesView",
    component: EmployeesView,
  },
  {
    path: "/students",
    name: "StudentView",
    component: StudentView,
  },
  {
    path: "/students/add",
    name: "AddStudent",
    component: AddStudentView,
  },
  {
    path: "/students/:id/edit",
    name: "EditStudent",
    component: EditStudentView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("user");
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
