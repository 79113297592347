<template>
  <div :class="{ 'app-container': true, 'with-sidebar': isAuthenticated }">
    <NavigationMenu v-if="isAuthenticated" />
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import NavigationMenu from "./components/NavigationMenu.vue";

export default defineComponent({
  components: {
    NavigationMenu,
  },
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    return { isAuthenticated };
  },
});
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.with-sidebar main {
  margin-left: 250px;
}

main {
  flex: 1;
  padding: 20px;
}

@media (max-width: 768px) {
  .with-sidebar main {
    margin-left: 0;
  }

  .side-nav {
    width: 100%;
    position: relative;
    box-shadow: none;
  }
}
</style>
