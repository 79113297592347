<template>
  <div class="edit-organization-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/organizations">Schools</router-link> /
      <span>Edit School</span>
    </nav>

    <h2 class="title">Edit School</h2>

    <form @submit.prevent="saveOrganization">
      <div class="tabs">
        <div
          :class="{ active: selectedTab === 'General Info' }"
          @click="selectedTab = 'General Info'"
        >
          General Info
        </div>
        <div
          :class="{ active: selectedTab === 'Grades' }"
          @click="selectedTab = 'Grades'"
        >
          Grades
        </div>
        <div
          :class="{ active: selectedTab === 'Location' }"
          @click="selectedTab = 'Location'"
        >
          Location
        </div>
        <div
          :class="{ active: selectedTab === 'Groups' }"
          @click="selectedTab = 'Groups'"
        >
          Groups
        </div>
      </div>
      <div class="tab-content">
        <!-- General Info Tab -->
        <div v-show="selectedTab === 'General Info'">
          <div class="form-group">
            <label for="districtName">District Name:</label>
            <input v-model="organization.districtName" id="districtName" />
          </div>
          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="organization.name" id="name" required />
          </div>
          <div class="form-group">
            <label for="organizationType">Organization Type:</label>
            <input
              v-model="organization.organizationType"
              id="organizationType"
            />
          </div>
          <div class="form-group">
            <label for="organizationCode">Organization Code:</label>
            <input
              v-model="organization.organizationCode"
              id="organizationCode"
            />
          </div>
          <div class="form-group">
            <label for="address">Address:</label>
            <input v-model="organization.address" id="address" />
          </div>
          <div class="form-group">
            <label for="city">City:</label>
            <input v-model="organization.city" id="city" required />
          </div>
          <div class="form-group">
            <label for="zipcode">Zipcode:</label>
            <input v-model="organization.zipcode" id="zipcode" />
          </div>
          <div class="form-group">
            <label for="phone">Phone:</label>
            <input v-model="organization.phone" id="phone" />
          </div>
          <div class="form-group">
            <label for="website">Website:</label>
            <input v-model="organization.website" id="website" />
          </div>
        </div>

        <!-- Grades Tab -->
        <div v-show="selectedTab === 'Grades'">
          <div class="form-group">
            <label for="prekindergarten">Prekindergarten:</label>
            <input
              type="checkbox"
              v-model="organization.prekindergarten"
              id="prekindergarten"
            />
          </div>
          <div class="form-group">
            <label for="kindergarten">Kindergarten:</label>
            <input
              type="checkbox"
              v-model="organization.kindergarten"
              id="kindergarten"
            />
          </div>
          <div class="form-group">
            <label for="grade1">Grade 1:</label>
            <input type="checkbox" v-model="organization.grade1" id="grade1" />
          </div>
          <div class="form-group">
            <label for="grade2">Grade 2:</label>
            <input type="checkbox" v-model="organization.grade2" id="grade2" />
          </div>
          <div class="form-group">
            <label for="grade3">Grade 3:</label>
            <input type="checkbox" v-model="organization.grade3" id="grade3" />
          </div>
          <div class="form-group">
            <label for="grade4">Grade 4:</label>
            <input type="checkbox" v-model="organization.grade4" id="grade4" />
          </div>
          <div class="form-group">
            <label for="grade5">Grade 5:</label>
            <input type="checkbox" v-model="organization.grade5" id="grade5" />
          </div>
          <div class="form-group">
            <label for="grade6">Grade 6:</label>
            <input type="checkbox" v-model="organization.grade6" id="grade6" />
          </div>
          <div class="form-group">
            <label for="grade7">Grade 7:</label>
            <input type="checkbox" v-model="organization.grade7" id="grade7" />
          </div>
          <div class="form-group">
            <label for="grade8">Grade 8:</label>
            <input type="checkbox" v-model="organization.grade8" id="grade8" />
          </div>
          <div class="form-group">
            <label for="grade9">Grade 9:</label>
            <input type="checkbox" v-model="organization.grade9" id="grade9" />
          </div>
          <div class="form-group">
            <label for="grade10">Grade 10:</label>
            <input
              type="checkbox"
              v-model="organization.grade10"
              id="grade10"
            />
          </div>
          <div class="form-group">
            <label for="grade11">Grade 11:</label>
            <input
              type="checkbox"
              v-model="organization.grade11"
              id="grade11"
            />
          </div>
          <div class="form-group">
            <label for="grade12">Grade 12:</label>
            <input
              type="checkbox"
              v-model="organization.grade12"
              id="grade12"
            />
          </div>
        </div>

        <!-- Location Tab -->
        <div v-show="selectedTab === 'Location'">
          <div class="form-group">
            <label for="latitude">Latitude:</label>
            <input v-model="organization.latitude" id="latitude" required />
          </div>
          <div class="form-group">
            <label for="longitude">Longitude:</label>
            <input v-model="organization.longitude" id="longitude" required />
          </div>
        </div>

        <!-- Groups Tab -->
        <div v-show="selectedTab === 'Groups'">
          <div
            v-for="(group, index) in organization.groups"
            :key="index"
            class="group-container"
          >
            <div class="form-group">
              <label :for="'groupName' + index">Group Name:</label>
              <input v-model="group.name" :id="'groupName' + index" />
            </div>
            <div class="form-group">
              <label :for="'pickupNotes' + index">Pickup Notes:</label>
              <input v-model="group.pickupNotes" :id="'pickupNotes' + index" />
            </div>
            <div class="form-group">
              <label :for="'dropOffNotes' + index">Drop-off Notes:</label>
              <input
                v-model="group.dropOffNotes"
                :id="'dropOffNotes' + index"
              />
            </div>
            <div class="form-group">
              <label :for="'bellTime' + index">Bell Time:</label>
              <input
                type="time"
                v-model="group.bellTime"
                :id="'bellTime' + index"
              />
            </div>
            <div class="form-group">
              <label :for="'dismissalTime' + index">Dismissal Time:</label>
              <input
                type="time"
                v-model="group.dismissalTime"
                :id="'dismissalTime' + index"
              />
            </div>
            <div class="form-group">
              <label :for="'startDate' + index">Start Date:</label>
              <input
                type="date"
                v-model="group.startDate"
                :id="'startDate' + index"
              />
            </div>
            <div class="form-group">
              <label :for="'endDate' + index">End Date:</label>
              <input
                type="date"
                v-model="group.endDate"
                :id="'endDate' + index"
              />
            </div>
            <button
              type="button"
              @click="removeGroup(index)"
              class="remove-button"
            >
              Remove Group
            </button>
          </div>
          <button type="button" @click="addGroup" class="add-group-button">
            Add Group
          </button>
        </div>
      </div>
      <button type="submit" class="save-button">Save</button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { API_URL } from "../config";

const route = useRoute();
const organization = ref({
  id: 0,
  districtName: "",
  name: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  city: "",
  zipcode: "",
  phone: "",
  website: "",
  prekindergarten: 0,
  kindergarten: 0,
  grade1: 0,
  grade2: 0,
  grade3: 0,
  grade4: 0,
  grade5: 0,
  grade6: 0,
  grade7: 0,
  grade8: 0,
  grade9: 0,
  grade10: 0,
  grade11: 0,
  grade12: 0,
  latitude: "0",
  longitude: "0",
  groups: [], // Initialize groups array
});

const selectedTab = ref("General Info");

const fetchOrganization = async (id) => {
  try {
    const response = await fetch(`${API_URL}/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      Object.assign(organization.value, await response.json());
    } else {
      console.error("Failed to fetch organization:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching organization:", error);
  }
};

const saveOrganization = async () => {
  try {
    const response = await fetch(
      `${API_URL}/organizations/${organization.value.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(organization.value),
      }
    );
    if (response.ok) {
      // Re-fetch the organization data to reload the view with the saved data
      fetchOrganization(organization.value.id);
      alert("Organization updated successfully!"); // Optional: Show a success message
    } else {
      console.error("Failed to save organization:", response.statusText);
    }
  } catch (error) {
    console.error("Error saving organization:", error);
  }
};

const addGroup = () => {
  organization.value.groups.push({
    name: "",
    pickupNotes: "",
    dropOffNotes: "",
    bellTime: "",
    dismissalTime: "",
    startDate: "",
    endDate: "",
  });
};

const removeGroup = (index) => {
  organization.value.groups.splice(index, 1);
};

onMounted(() => {
  const id = Number(route.params.id);
  if (id) {
    fetchOrganization(id);
  }
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
  background-color: #fff;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
  background-color: #fff;
}

.breadcrumb span {
  color: #6c757d;
  background-color: #fff;
}

.edit-organization-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="time"],
input[type="date"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tabs div {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.tabs div.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.tab-content {
  padding: 20px;
}

.group-container {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.add-group-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.remove-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
}

.save-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  width: 100%;
}
</style>
