<template>
  <div class="view-organization-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/organizations">Schools</router-link> /
      <span>View School</span>
    </nav>

    <h2 class="title">View School</h2>

    <div class="tabs">
      <div
        :class="{ active: selectedTab === 'General Info' }"
        @click="selectedTab = 'General Info'"
      >
        General Info
      </div>
      <div
        :class="{ active: selectedTab === 'Grades' }"
        @click="selectedTab = 'Grades'"
      >
        Grades
      </div>
      <div
        :class="{ active: selectedTab === 'Location' }"
        @click="selectedTab = 'Location'"
      >
        Location
      </div>
      <div
        :class="{ active: selectedTab === 'Groups' }"
        @click="selectedTab = 'Groups'"
      >
        Groups
      </div>
    </div>

    <div class="tab-content">
      <!-- General Info Tab -->
      <div v-show="selectedTab === 'General Info'">
        <div class="form-group">
          <label>District Name:</label>
          <p>{{ organization.districtName }}</p>
        </div>
        <div class="form-group">
          <label>Name:</label>
          <p>{{ organization.name }}</p>
        </div>
        <div class="form-group">
          <label>Organization Type:</label>
          <p>{{ organization.organizationType }}</p>
        </div>
        <div class="form-group">
          <label>Organization Code:</label>
          <p>{{ organization.organizationCode }}</p>
        </div>
        <div class="form-group">
          <label>Address:</label>
          <p>{{ organization.address }}</p>
        </div>
        <div class="form-group">
          <label>City:</label>
          <p>{{ organization.city }}</p>
        </div>
        <div class="form-group">
          <label>State:</label>
          <p>{{ organization.state }}</p>
        </div>
        <div class="form-group">
          <label>Zipcode:</label>
          <p>{{ organization.zipcode }}</p>
        </div>
        <div class="form-group">
          <label>Phone:</label>
          <p>{{ organization.phone }}</p>
        </div>
        <div class="form-group">
          <label>Website:</label>
          <p>{{ organization.website }}</p>
        </div>
      </div>

      <!-- Grades Tab -->
      <div v-show="selectedTab === 'Grades'">
        <div class="form-group">
          <label>Prekindergarten:</label>
          <p>{{ organization.prekindergarten ? "Yes" : "No" }}</p>
        </div>
        <div class="form-group">
          <label>Kindergarten:</label>
          <p>{{ organization.kindergarten ? "Yes" : "No" }}</p>
        </div>
        <div class="form-group">
          <label>Grade 1:</label>
          <p>{{ organization.grade1 ? "Yes" : "No" }}</p>
        </div>
        <!-- Add other grades similarly -->
      </div>

      <!-- Location Tab -->
      <div v-show="selectedTab === 'Location'">
        <div class="form-group">
          <label>Latitude:</label>
          <p>{{ organization.latitude }}</p>
        </div>
        <div class="form-group">
          <label>Longitude:</label>
          <p>{{ organization.longitude }}</p>
        </div>
      </div>

      <!-- Groups Tab -->
      <div v-show="selectedTab === 'Groups'">
        <div
          v-for="(group, index) in organization.groups"
          :key="index"
          class="group-container"
        >
          <div class="form-group">
            <label>Group Name:</label>
            <p>{{ group.name }}</p>
          </div>
          <div class="form-group">
            <label>Pickup Notes:</label>
            <p>{{ group.pickupNotes }}</p>
          </div>
          <div class="form-group">
            <label>Drop-off Notes:</label>
            <p>{{ group.dropOffNotes }}</p>
          </div>
          <div class="form-group">
            <label>Bell Time:</label>
            <p>{{ group.bellTime }}</p>
          </div>
          <div class="form-group">
            <label>Dismissal Time:</label>
            <p>{{ group.dismissalTime }}</p>
          </div>
          <div class="form-group">
            <label>Start Date:</label>
            <p>{{ group.startDate }}</p>
          </div>
          <div class="form-group">
            <label>End Date:</label>
            <p>{{ group.endDate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { API_URL } from "../config";

const route = useRoute();
const organization = ref({
  id: 0,
  districtName: "",
  name: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  website: "",
  prekindergarten: 0,
  kindergarten: 0,
  grade1: 0,
  grade2: 0,
  grade3: 0,
  grade4: 0,
  grade5: 0,
  grade6: 0,
  grade7: 0,
  grade8: 0,
  grade9: 0,
  grade10: 0,
  grade11: 0,
  grade12: 0,
  latitude: "0",
  longitude: "0",
  groups: [], // Initialize groups array
});

const selectedTab = ref("General Info");

const fetchOrganization = async (id) => {
  try {
    const response = await fetch(`${API_URL}/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      Object.assign(organization.value, await response.json());
    } else {
      console.error("Failed to fetch organization:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching organization:", error);
  }
};

onMounted(() => {
  const id = Number(route.params.id);
  if (id) {
    fetchOrganization(id);
  }
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
  background-color: #fff;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
  background-color: #fff;
}

.breadcrumb span {
  color: #6c757d;
  background-color: #fff;
}

.view-organization-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

p {
  margin: 0;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tabs div {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.tabs div.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.tab-content {
  padding: 20px;
}

.group-container {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
</style>
