<template>
  <div class="districts-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Districts</span>
    </nav>

    <h2 class="title">Districts</h2>

    <button @click="createNewDistrict" class="create-button">
      Create New District
    </button>

    <!-- Filter input for district name -->
    <div class="filter-container">
      <input v-model="searchQuery" placeholder="Filter by name..." />
    </div>

    <!-- Pagination control for items per page -->
    <div class="pagination-controls">
      <label for="itemsPerPage">Show</label>
      <select v-model="itemsPerPage" id="itemsPerPage">
        <option
          v-for="option in itemsPerPageOptions"
          :key="option"
          :value="option"
        >
          {{ option === -1 ? "All" : option }}
        </option>
      </select>
      <span>entries</span>
    </div>

    <table class="districts-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th @click="sortBySchoolsCount" class="sortable">
            Schools Count
            <span v-if="sortDirection === 'asc'">&#9650;</span>
            <span v-else>&#9660;</span>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="district in paginatedDistricts" :key="district.id">
          <td>{{ district.id }}</td>
          <td>{{ district.name }}</td>
          <td>
            {{ district.organizations ? district.organizations.length : 0 }}
          </td>
          <td>
            <button @click="editDistrict(district.id)" class="edit-button">
              Edit
            </button>
            <button @click="deleteDistrict(district.id)" class="delete-button">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"; // Import 'watch'
import { useRouter } from "vue-router";
import { API_URL } from "../config";

const districts = ref([]);
const searchQuery = ref("");
const sortDirection = ref("asc"); // Default sort direction
const itemsPerPage = ref(10); // Default items per page
const currentPage = ref(1); // Default current page

const itemsPerPageOptions = ref([10, 30, 50, 100, -1]); // -1 means "All"

const router = useRouter();

const fetchDistricts = async () => {
  try {
    const response = await fetch(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      districts.value = await response.json();
    } else {
      console.error("Failed to fetch districts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

const createNewDistrict = () => {
  router.push("/districts/new");
};

const editDistrict = (id) => {
  router.push(`/districts/${id}/edit`);
};

const deleteDistrict = async (id) => {
  if (confirm("Are you sure you want to delete this district?")) {
    try {
      const response = await fetch(`${API_URL}/districts/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        fetchDistricts(); // Refresh the list after deletion
      } else {
        console.error("Failed to delete district:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting district:", error);
    }
  }
};

// Computed property for filtered, sorted, and paginated districts
const filteredDistricts = computed(() => {
  let sortedDistricts = districts.value.slice().sort((a, b) => {
    const countA = a.organizations ? a.organizations.length : 0;
    const countB = b.organizations ? b.organizations.length : 0;
    if (sortDirection.value === "asc") {
      return countA - countB;
    } else {
      return countB - countA;
    }
  });

  return sortedDistricts.filter((district) =>
    district.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Paginated districts based on items per page and current page
const paginatedDistricts = computed(() => {
  if (itemsPerPage.value === -1) {
    return filteredDistricts.value; // Show all if "All" is selected
  }

  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredDistricts.value.slice(start, end);
});

// Function to toggle sorting direction
const sortBySchoolsCount = () => {
  sortDirection.value = sortDirection.value === "asc" ? "desc" : "desc";
};

onMounted(fetchDistricts);
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.districts-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.create-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.filter-container {
  margin-bottom: 20px;
}

.filter-container input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.pagination-controls {
  margin-bottom: 20px;
}

.pagination-controls select {
  padding: 5px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.districts-table {
  width: 100%;
  border-collapse: collapse;
}

.districts-table th,
.districts-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.districts-table th.sortable {
  cursor: pointer;
}

.districts-table th.sortable:hover {
  background-color: #f1f1f1;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-buttons button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.pagination-buttons button.active {
  background-color: #0056b3;
}

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
}

.delete-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}
</style>
