<template>
  <div class="edit-student-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/students">Students</router-link> /
      <span>Edit Student</span>
    </nav>

    <h2 class="title">Edit Student</h2>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
    </div>

    <!-- Multi-Step Form -->
    <form @submit.prevent="submitForm">
      <!-- Step 1: General Info -->
      <div v-if="currentStep === 1">
        <h3>General Information</h3>
        <div class="form-group">
          <label for="firstName">First Name:</label>
          <input v-model="student.firstName" id="firstName" required />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name:</label>
          <input v-model="student.lastName" id="lastName" required />
        </div>
        <div class="form-group">
          <label for="addressLine1">Address Line 1:</label>
          <input v-model="student.addressLine1" id="addressLine1" required />
        </div>
        <div class="form-group">
          <label for="city">City:</label>
          <input v-model="student.city" id="city" required />
        </div>
        <div class="form-group">
          <label for="state">State:</label>
          <input v-model="student.state" id="state" required />
        </div>
        <div class="form-group">
          <label for="zip">Zip:</label>
          <input v-model="student.zip" id="zip" required />
        </div>

        <!-- School Search Filter -->
        <div class="form-group">
          <label for="school-search">Search School (by name or address):</label>
          <input
            type="text"
            v-model="schoolSearch"
            id="school-search"
            placeholder="Type school name or address to filter"
          />
        </div>

        <!-- Filtered List of Schools -->
        <div class="filtered-schools">
          <ul>
            <li
              v-for="school in filteredSchools"
              :key="school.id"
              @click="selectSchool(school)"
              class="school-item"
              :class="{ selected: school.id === student.schoolId }"
            >
              <strong>{{ school.name }}</strong> - {{ school.address }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Step 2: Needs Info -->
      <div v-if="currentStep === 2">
        <h3>Needs Information</h3>
        <div class="form-group">
          <label for="comments">Comments:</label>
          <textarea v-model="student.comments" id="comments"></textarea>
        </div>
        <div class="form-group">
          <label for="needs">Needs:</label>
          <div class="tags-input">
            <span v-for="(need, index) in needsArray" :key="index" class="tag">
              {{ need }}
              <span class="tag-close" @click="removeNeed(index)">x</span>
            </span>
            <input
              v-model="newNeed"
              @keydown.enter.prevent="addNeed"
              @blur="addNeed"
              placeholder="Add a need"
              list="suggested-needs"
            />
            <datalist id="suggested-needs">
              <option
                v-for="option in suggestedNeeds"
                :key="option"
                :value="option"
              />
            </datalist>
          </div>
        </div>
      </div>

      <!-- Step 3: Review Information -->
      <div v-if="currentStep === 3">
        <h3>Review & Submit</h3>
        <p>
          <strong>Name:</strong> {{ student.firstName }} {{ student.lastName }}
        </p>
        <p>
          <strong>Address:</strong> {{ student.addressLine1 }},
          {{ student.city }}, {{ student.state }} {{ student.zip }}
        </p>
        <p><strong>School:</strong> {{ selectedSchoolName }}</p>
        <p><strong>Comments:</strong> {{ student.comments }}</p>
        <p><strong>Needs:</strong> {{ needsArray.join(", ") }}</p>
      </div>

      <!-- Navigation Buttons -->
      <div class="form-navigation">
        <button v-if="currentStep > 1" @click="previousStep" type="button">
          Back
        </button>
        <button v-if="currentStep < totalSteps" @click="nextStep" type="button">
          Next
        </button>
        <button v-if="currentStep === totalSteps" type="submit">Update</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { API_URL } from "../config";

// Student model
const student = ref({
  firstName: "",
  lastName: "",
  addressLine1: "",
  city: "",
  state: "",
  zip: "",
  comments: "",
  needs: "",
  schoolId: "", // Field to store selected school ID
});

// List of available schools and search term
const schools = ref([]);
const schoolSearch = ref("");

// Fetch the list of schools from the API
const fetchSchools = async () => {
  try {
    const response = await fetch(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      schools.value = await response.json(); // Populates the schools list
    } else {
      console.error("Failed to fetch schools:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching schools:", error);
  }
};

// Fetch existing student data for EditStudentView
const route = useRoute();
const fetchStudent = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/students/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      student.value = { ...data, needs: "" }; // Reset needs string
      needsArray.value = data.needs
        .split(",")
        .map((need: string) => need.trim());
      student.value.schoolId = data.school?.id || ""; // Assign school ID if it exists
    } else {
      console.error("Failed to fetch student:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching student:", error);
  }
};

onMounted(() => {
  fetchSchools(); // Fetch schools when component is mounted
  const studentId = route.params.id;
  fetchStudent(Number(studentId)); // Fetch student data in edit view
});

// Computed property to filter schools based on the search input
const filteredSchools = computed(() => {
  const searchTerm = schoolSearch.value.toLowerCase();
  return schools.value.filter(
    (school) =>
      school.name.toLowerCase().includes(searchTerm) ||
      school.address.toLowerCase().includes(searchTerm)
  );
});

// Function to select a school
const selectSchool = (school) => {
  student.value.schoolId = school.id;
};

// Computed property to get the selected school's name
const selectedSchoolName = computed(() => {
  const selectedSchool = schools.value.find(
    (school) => school.id === student.value.schoolId
  );
  return selectedSchool ? selectedSchool.name : "";
});

// Needs array handling
const needsArray = ref<string[]>([]);
const newNeed = ref("");
const currentStep = ref(1);
const totalSteps = 3;

// Form submission
const router = useRouter();
const submitForm = async () => {
  try {
    student.value.needs = needsArray.value.join(", ");
    const response = await fetch(`${API_URL}/students/${route.params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(student.value),
    });

    if (response.ok) {
      alert("Student updated successfully!");
      router.push("/students");
    } else {
      const error = await response.json();
      alert(`Error: ${error.message}`);
    }
  } catch (error) {
    console.error("Error updating student:", error);
    alert("An error occurred while updating the student.");
  }
};

// Add and remove needs functionality
const addNeed = () => {
  const trimmedNeed = newNeed.value.trim();
  if (trimmedNeed && !needsArray.value.includes(trimmedNeed)) {
    needsArray.value.push(trimmedNeed);
  }
  newNeed.value = ""; // Clear the input
};

const removeNeed = (index: number) => {
  needsArray.value.splice(index, 1);
};

// Navigation between steps
const nextStep = () => {
  if (currentStep.value < totalSteps) currentStep.value++;
};

const previousStep = () => {
  if (currentStep.value > 1) currentStep.value--;
};

// Progress bar logic
const progressPercentage = computed(() => {
  return (currentStep.value / totalSteps) * 100;
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.edit-student-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.progress-bar {
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress-bar .progress {
  height: 100%;
  background-color: #007bff;
}

.form-group {
  margin-bottom: 15px;
}

input[type="text"],
textarea,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.filtered-schools {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filtered-schools ul {
  list-style: none;
  padding: 0;
}

.filtered-schools .school-item {
  padding: 10px;
  cursor: pointer;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.filtered-schools .school-item:hover,
.filtered-schools .school-item.selected {
  background-color: #007bff;
  color: #fff;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}
</style>
