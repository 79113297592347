<template>
  <div>
    <nav :class="{ 'side-nav': !isMobile, 'top-nav': isMobile }">
      <div v-if="isMobile" class="hamburger" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <ul
        v-show="!isMobile || isMenuOpen"
        :class="{ 'menu-grid': !isMobile, 'menu-list': isMobile }"
      >
        <li
          v-for="item in menuItems"
          :key="item.name"
          @click="item.action ? item.action() : null"
          class="menu-item"
        >
          <router-link v-if="!item.action" :to="item.route">
            <div class="tile">
              <font-awesome-icon :icon="item.icon" />
              <span>{{ item.name }}</span>
            </div>
          </router-link>
          <div v-else class="tile">
            <font-awesome-icon :icon="item.icon" />
            <span>{{ item.name }}</span>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSchool,
  faCity,
  faAddressBook,
  faSignOutAlt,
  faHome, // Import Home icon
  faUserTie, // Import Employee icon
  faUserGraduate, // Import Student icon
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSchool,
  faCity,
  faAddressBook,
  faSignOutAlt,
  faHome,
  faUserTie,
  faUserGraduate
);

export default defineComponent({
  name: "NavigationMenu",
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter(); // Access Vue Router
    const isMobile = ref(window.innerWidth <= 768);
    const isMenuOpen = ref(false);

    const checkMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const logout = () => {
      store.dispatch("logout");
      router.push("/login"); // Redirect to the login page after logging out
    };

    const menuItems = [
      { name: "NavaHome", route: "/", icon: "home" }, // Home item
      { name: "Schools", route: "/Organizations", icon: "school" },
      { name: "Districts", route: "/districts", icon: "city" },
      { name: "Contacts", route: "/contacts", icon: "address-book" },
      { name: "Employee", route: "/employees", icon: "user-tie" }, // Employee item
      { name: "Students", route: "/students", icon: "user-graduate" }, // Student item
      { name: "Logout", action: logout, icon: "sign-out-alt" },
    ];

    onMounted(() => {
      window.addEventListener("resize", checkMobile);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkMobile);
    });

    return { isMobile, isMenuOpen, toggleMenu, menuItems };
  },
});
</script>

<style scoped>
.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px; /* Adjusted width to better fit the tiles */
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.menu-list {
  display: flex;
  flex-direction: column;
}

.top-nav {
  position: relative;
  width: 100%;
  background-color: #2c3e50;
  color: #ecf0f1;
  /* Removed styles for mobile view */
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #ecf0f1;
  margin: 4px 0;
  transition: 0.4s;
}

.menu-item {
  list-style-type: none;
  padding: 0;
}

.menu-item a,
.menu-item div {
  color: #ecf0f1;
  text-decoration: none;
  font-weight: bold;
  display: block;
  text-align: center;
}

.tile {
  background-color: #34495e;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  height: 80px;
  cursor: pointer;
}

.tile svg {
  font-size: 24px;
  margin-bottom: 10px;
}

.tile span {
  font-size: 14px;
}

.tile:hover {
  background-color: #2980b9;
}

button {
  background-color: #e74c3c;
  color: #ecf0f1;
  border: none;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  text-align: center;
}

button:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .side-nav {
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: none;
    padding: 0;
  }

  .menu-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .menu-item {
    width: 100%;
    margin: 0;
  }

  .tile {
    width: 100%;
    height: auto;
    padding: 15px;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
  }

  .tile svg {
    margin-right: 15px;
    font-size: 20px;
  }

  .tile span {
    font-size: 16px;
  }

  .top-nav {
    padding: 0;
    box-shadow: none;
    display: block;
  }
}
</style>
