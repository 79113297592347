<template>
  <div class="employees-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Employees</span>
    </nav>

    <h2 class="title">Employees</h2>

    <button @click="createNewEmployee" class="create-button">
      Create New Employee
    </button>

    <table class="employees-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Department</th>
          <th>Position</th>
          <th>Phone</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="employee in employees" :key="employee.id">
          <td>{{ employee.id }}</td>
          <td>{{ employee.name }}</td>
          <td>{{ employee.department }}</td>
          <td>{{ employee.position }}</td>
          <td>{{ employee.phone }}</td>
          <td>
            <button @click="editEmployee(employee.id)" class="edit-button">
              Edit
            </button>
            <button @click="deleteEmployee(employee.id)" class="delete-button">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";

const employees = ref([]);
const router = useRouter();

const fetchEmployees = async () => {
  try {
    const response = await fetch(`${API_URL}/employees`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      employees.value = await response.json();
    } else {
      console.error("Failed to fetch employees:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching employees:", error);
  }
};

const createNewEmployee = () => {
  router.push("/employees/new");
};

const editEmployee = (id) => {
  router.push(`/employees/${id}/edit`);
};

const deleteEmployee = async (id) => {
  if (confirm("Are you sure you want to delete this employee?")) {
    try {
      const response = await fetch(`${API_URL}/employees/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        fetchEmployees(); // Refresh the list after deletion
      } else {
        console.error("Failed to delete employee:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  }
};

onMounted(fetchEmployees);
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.employees-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.create-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.employees-table {
  width: 100%;
  border-collapse: collapse;
}

.employees-table th,
.employees-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
}

.delete-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}
</style>
