<template>
  <div class="login-container">
    <div class="login-box">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" v-model="username" required />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit" class="login-button">Login</button>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { API_URL } from "../config"; // Import the API URL from the config file

export default defineComponent({
  setup() {
    const username = ref("");
    const password = ref("");
    const errorMessage = ref("");
    const store = useStore();
    const router = useRouter();

    const login = async () => {
      try {
        const response = await fetch(`${API_URL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            login: username.value,
            password: password.value,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.token) {
            localStorage.setItem("token", data.token); // Save token to localStorage
            store.dispatch("login", data.token);
            router.push("/");
          } else {
            errorMessage.value = "Invalid login response";
          }
        } else {
          const errorData = await response.json();
          errorMessage.value =
            errorData.message || "Invalid username or password";
        }
      } catch (error) {
        errorMessage.value = "An error occurred. Please try again.";
      }
    };

    return { username, password, login, errorMessage };
  },
});
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.login-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .login-box {
    padding: 1rem;
  }

  input {
    padding: 0.5rem;
  }

  .login-button {
    padding: 0.5rem;
  }
}
</style>
