<template>
  <div class="import-data-container">
    <h2>Imported School Data</h2>
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Search by name or address..."
      class="search-input"
    />
    <table class="data-table">
      <thead>
        <tr>
          <th>School Name</th>
          <th>Address</th>
          <th>Type</th>
          <th>District</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(school, index) in filteredSchools"
          :key="school.organization_code"
        >
          <tr @click="toggleExpand(index)" class="expandable-row">
            <td>
              <span class="expand-icon">
                {{ expandedIndex === index ? "▼" : "▶" }}
              </span>
              {{ school.name }}
            </td>
            <td>{{ school.address }}</td>
            <td>{{ school.organization_type }}</td>
            <td>{{ school.district_name }}</td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="4">
              <div><strong>School Name:</strong> {{ school.name }}</div>
              <div><strong>Address:</strong> {{ school.address }}</div>
              <div><strong>Type:</strong> {{ school.organization_type }}</div>
              <div><strong>District:</strong> {{ school.district_name }}</div>
              <div><strong>Town:</strong> {{ school.town }}</div>
              <div><strong>Zipcode:</strong> {{ school.zipcode }}</div>
              <div><strong>Phone:</strong> {{ school.phone }}</div>
              <div>
                <strong>Grades:</strong>
                Pre-K: {{ school.prekindergarten }}, K:
                {{ school.kindergarten }}, 1: {{ school.grade_1 }}, 2:
                {{ school.grade_2 }}, 3: {{ school.grade_3 }}, 4:
                {{ school.grade_4 }}, 5: {{ school.grade_5 }}, 6:
                {{ school.grade_6 }}, 7: {{ school.grade_7 }}, 8:
                {{ school.grade_8 }}, 9: {{ school.grade_9 }}, 10:
                {{ school.grade_10 }}, 11: {{ school.grade_11 }}, 12:
                {{ school.grade_12 }}
              </div>
              <div>
                <strong>Student Open Date:</strong>
                {{ new Date(school.student_open_date).toLocaleDateString() }}
              </div>
              <div>
                <strong>Magnet:</strong>
                {{ school.interdistrict_magnet === "1" ? "Yes" : "No" }}
              </div>
              <div>
                <strong>Latitude:</strong> {{ school.geocoded_column.latitude }}
              </div>
              <div>
                <strong>Longitude:</strong>
                {{ school.geocoded_column.longitude }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";

interface School {
  district_name: string;
  name: string;
  organization_type: string;
  organization_code: string;
  address: string;
  town: string;
  zipcode: string;
  phone: string;
  prekindergarten: string;
  kindergarten: string;
  grade_1: string;
  grade_2: string;
  grade_3: string;
  grade_4: string;
  grade_5: string;
  grade_6: string;
  grade_7: string;
  grade_8: string;
  grade_9: string;
  grade_10: string;
  grade_11: string;
  grade_12: string;
  student_open_date: string;
  interdistrict_magnet: string;
  geocoded_column: {
    latitude: string;
    longitude: string;
  };
}

const CACHE_KEY = "school_data";
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

export default defineComponent({
  name: "ImportData",
  setup() {
    const schools = ref<School[]>([]);
    const searchQuery = ref<string>("");
    const expandedIndex = ref<number | null>(null);

    const fetchSchoolData = async () => {
      try {
        const cachedData = localStorage.getItem(CACHE_KEY);
        const cacheTimestamp = localStorage.getItem(`${CACHE_KEY}_timestamp`);
        const now = new Date().getTime();

        if (
          cachedData &&
          cacheTimestamp &&
          now - parseInt(cacheTimestamp) < CACHE_EXPIRY
        ) {
          schools.value = JSON.parse(cachedData);
          console.log("Loaded data from cache");
        } else {
          const response = await fetch(
            "https://data.ct.gov/resource/9k2y-kqxn.json?$limit=5000"
          );
          const data: School[] = await response.json();
          schools.value = data;
          localStorage.setItem(CACHE_KEY, JSON.stringify(data));
          localStorage.setItem(`${CACHE_KEY}_timestamp`, now.toString());
          console.log("Fetched data from API and cached it");
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    onMounted(() => {
      fetchSchoolData();
    });

    const toggleExpand = (index: number) => {
      expandedIndex.value = expandedIndex.value === index ? null : index;
    };

    const filteredSchools = computed<School[]>(() => {
      if (!searchQuery.value) {
        return schools.value;
      }
      return schools.value.filter((school) => {
        return (
          school.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          school.address.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      });
    });

    return { searchQuery, toggleExpand, filteredSchools, expandedIndex };
  },
});
</script>

<style scoped>
.import-data-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-table tbody tr:hover {
  background-color: #f9f9f9;
}

.expandable-row {
  cursor: pointer;
}

.expand-icon {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
}
</style>
